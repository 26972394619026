import { CommonContexts } from "@epam/uui-core";

export const UPLOAD_TYPE = {
  FILE: "file",
  FOLDER: "folder",
};

export const FORMAT_TYPE = {
  ORIGINAL: "original",
  SHORT: "short",
  CONCISE: "concise",
};

export interface IFileType extends File {
  path: string;
}

export interface INotificationType {
  SUCCESS: string;
  ERROR: string;
  WARNING: string;
}
export const NOTIFICATION_TYPE: { [key: string]: keyof INotificationType } = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  WARNING: "WARNING",
};

export interface IContextData {
  formatType: string;
  uploadedResumes: Array<IFileType>;
}

export interface ExtendedCommonContexts extends CommonContexts<any, any> {
  contextData: IContextData;
  setContextData: (data: IContextData) => void;
}
export interface InputAttributes
  extends React.InputHTMLAttributes<HTMLInputElement> {
  webkitdirectory?: string | undefined;
  directory?: string | undefined;
}
export interface DropzoneProps {
  onDrop: () => void;
  noClick: boolean;
  noKeyboard: boolean;
  directory?: boolean;
}

export type ConvertedResume = {
  resumeName: string;
  downloadLink: string;
};

export type ResumeApiData = {
  convertedResumes?: ConvertedResume[];
  nonConvertedResumes?: string[];
};

export class ApiError extends Error {
  public status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
    this.name = "ApiError";
  }
}

export const DOWNLOAD_RESUME_URL =
  "https://resumebuilder.lab.epam.com/home/api/download";
