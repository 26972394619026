import { Text } from '@epam/uui'
import React from 'react'
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer>
        <Text
          fontSize="18"
          lineHeight="30"
          fontWeight="600"
          color="disabled"
        >
          Made by EPAM Garage Team
        </Text>
    </footer>
  )
}

export default Footer
