import React, { useState } from "react";
import { FlexCell, FlexRow, Panel, Text, RadioInput, Button } from "@epam/uui";
import { useUuiContext } from "@epam/uui-core";
import {
  ExtendedCommonContexts,
  FORMAT_TYPE,
  IFileType,
} from "../../constants";
import conciseFormatImage from "../../static/concise_format.png";
import epamFormatImage from "../../static/epam_format.png";
import shortFormatImage from "../../static/short_format.png";
import "./ResumeFormatSelector.css";

interface IResumeFormatSelectorProps {
  uploadedFiles: Array<IFileType>;
}

export const ResumeFormatSelector: React.FC<IResumeFormatSelectorProps> = ({
  uploadedFiles,
}) => {
  const [format, setFormat] = useState<string>(FORMAT_TYPE.ORIGINAL);
  const { contextData, setContextData } =
    useUuiContext() as ExtendedCommonContexts;

  const handleConvert = () => {
    setContextData({
      ...contextData,
      formatType: format,
      uploadedResumes: uploadedFiles,
    });
  };

  return (
    <>
      <FlexRow padding="24" margin="24">
        <Text
          fontSize="24"
          fontWeight="700"
          lineHeight="30"
          cx="conversion-format-heading"
        >
          Choose a conversion format:
        </Text>
      </FlexRow>
      <Panel
        background="surface-main"
        shadow={true}
        cx="resume-format-container"
      >
        <FlexRow columnGap={"18"} rawProps={{ style: { width: "100%" } }}>
          <FlexCell grow={1} minWidth={150} cx="resume-item">
            <img
              src={epamFormatImage}
              alt="EPAM Format Resume"
              className="resume-preview"
            />
            <RadioInput
              label="EPAM Format Resume"
              value={format === FORMAT_TYPE.ORIGINAL}
              onValueChange={() => setFormat(FORMAT_TYPE.ORIGINAL)}
            />
          </FlexCell>
          <FlexCell grow={1} minWidth={150} cx="resume-item">
            <img
              src={shortFormatImage}
              alt="Short Executive Summary"
              className="resume-preview"
            />
            <RadioInput
              label="Short Executive Summary"
              value={format === FORMAT_TYPE.SHORT}
              onValueChange={() => setFormat(FORMAT_TYPE.SHORT)}
            />
          </FlexCell>
          <FlexCell grow={1} minWidth={150} cx="resume-item">
            <img
              src={conciseFormatImage}
              alt="Concise Resume"
              className="resume-preview"
            />
            <RadioInput
              label="Concise Resume"
              value={format === FORMAT_TYPE.CONCISE}
              onValueChange={() => setFormat(FORMAT_TYPE.CONCISE)}
            />
          </FlexCell>
        </FlexRow>
        <FlexRow
          rawProps={{
            style: {
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            },
          }}
        >
          <Button
            size="42"
            caption="Convert"
            onClick={handleConvert}
            rawProps={{ style: { textAlign: "center", width: "fit-content" } }}
            link={{ pathname: "/convert" }}
          />
        </FlexRow>
      </Panel>
    </>
  );
};
