// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resume-format-container {
  width: 75%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 20px;
}
.resume-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.resume-preview {
  width: 100%;
  height: 300px;
  object-fit: fill;
  margin-bottom: 10px;
}

.uui-radio {
  margin-top: 5px;
}

.conversion-format-heading {
  font-family: "Source Sans Pro", sans-serif;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 1.5);
  color: #fff;
  margin-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/ResumeFormatSelector/ResumeFormatSelector.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,cAAc;EACd,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,6BAA6B;EAC7B,uBAAuB;EACvB,aAAa;AACf;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,0CAA0C;EAC1C,2CAA2C;EAC3C,WAAW;EACX,aAAa;AACf","sourcesContent":[".resume-format-container {\n  width: 75%;\n  margin: 0 auto;\n  padding: 20px;\n  background-color: #f9f9f9;\n  border-radius: 8px;\n  display: flex;\n  justify-content: space-around;\n  align-items: flex-start;\n  padding: 20px;\n}\n.resume-item {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 5px;\n}\n\n.resume-preview {\n  width: 100%;\n  height: 300px;\n  object-fit: fill;\n  margin-bottom: 10px;\n}\n\n.uui-radio {\n  margin-top: 5px;\n}\n\n.conversion-format-heading {\n  font-family: \"Source Sans Pro\", sans-serif;\n  text-shadow: 3px 3px 6px rgba(0, 0, 0, 1.5);\n  color: #fff;\n  margin-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
