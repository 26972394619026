import React from "react";
import { MainMenu, Text } from "@epam/uui";
import { AdaptiveItemProps, MainMenuLogo } from "@epam/uui-components";
import epamLogo from "../../static/epamLogo.png";
import "./Header.css";

const headerElements: AdaptiveItemProps[] = [
  {
    id: "epamLogo",
    priority: 1,
    render: (p) => <MainMenuLogo key={p.id} href="/" logoUrl={epamLogo} />,
  },
  {
    id: "globalMenu",
    priority: 100,
    render: (p) => (
      <Text
        rawProps={{ style: { marginLeft: "auto" } }}
        color="white"
        lineHeight="30"
        size="48"
        fontWeight="700"
        key={p.id}
      >
        epam.AI Resume Builder
      </Text>
    ),
  },
];

const Header: React.FC = () => {
  return (
    <div className="header-container">
      <MainMenu
        rawProps={{
          style: {
            paddingInline: "2rem",
          },
        }}
        appLogoUrl={epamLogo}
        logoLink={{ pathname: "/" }}
        key="header-menu"
        items={headerElements}
      />
    </div>
  );
};

export default Header;
