// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-resume-section {
  display: flex;
  justify-content: space-around;
  margin-top: 2.5rem;
  padding-inline: 3%;
}

.converted-resumes-container {
  padding: 2%;
  width: 40%;
  border-radius: 10px;
}

.converted-resumes-container ul {
  padding-inline-start: 5%;
  margin: 0;
  list-style-type: none;
}

.seperator {
  height: 2px;
  width: 100%;
  background: grey;
}

.download-resumes-container {
  padding: 2%;
  width: 30%;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8%;
  align-items: center;
  border-radius: 10px;
}

.download-resumes-container button,
a {
  width: 100%;
}

.download-resumes-container b {
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/components/DownloadResumeSection/DownloadResumeSection.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,SAAS;EACT,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,UAAU;EACV,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,OAAO;EACP,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;;EAEE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".download-resume-section {\n  display: flex;\n  justify-content: space-around;\n  margin-top: 2.5rem;\n  padding-inline: 3%;\n}\n\n.converted-resumes-container {\n  padding: 2%;\n  width: 40%;\n  border-radius: 10px;\n}\n\n.converted-resumes-container ul {\n  padding-inline-start: 5%;\n  margin: 0;\n  list-style-type: none;\n}\n\n.seperator {\n  height: 2px;\n  width: 100%;\n  background: grey;\n}\n\n.download-resumes-container {\n  padding: 2%;\n  width: 30%;\n  max-height: fit-content;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  gap: 8%;\n  align-items: center;\n  border-radius: 10px;\n}\n\n.download-resumes-container button,\na {\n  width: 100%;\n}\n\n.download-resumes-container b {\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
