import "@epam/uui-components/styles.css";
import "@epam/uui/styles.css";
import "@epam/assets/theme/theme_loveship.scss";
import { StrictMode, useState } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserHistory } from "history";
import { Route, Router, Switch } from "react-router-dom";
import {
  DragGhost,
  HistoryAdaptedRouter,
  useUuiServices,
  UuiContext,
} from "@epam/uui-core";
import { ErrorHandler, ErrorPage, LinkButton, Snackbar } from "@epam/uui";
import { svc } from "./services";
import { HomePage } from "./pages/HomePage/HomePage";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ConvertedResumePage from "./pages/ConvertedResumePage/ConvertedResumePage";
import { IContextData, ExtendedCommonContexts, FORMAT_TYPE } from "./constants";
import "./index.module.scss";
import errorImage from "./static/ErrorImage.svg";

const history = createBrowserHistory();
const router = new HistoryAdaptedRouter(history);

function UuiEnhancedApp() {
  const { services } = useUuiServices({ router });
  Object.assign(svc, services);
  const [contextData, setContextData] = useState<IContextData>({
    formatType: FORMAT_TYPE.ORIGINAL,
    uploadedResumes: [],
  });

  return (
    <UuiContext.Provider
      value={
        { ...services, contextData, setContextData } as ExtendedCommonContexts
      }
    >
      <ErrorHandler>
        <Header />
        <Router history={history}>
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/convert" exact component={ConvertedResumePage} />
            <Route
              render={() => (
                <ErrorPage
                  title="404 - Page Not Found"
                  status={404}
                  imageUrl={errorImage}
                  subtitle={
                    <LinkButton
                      caption="Home page"
                      size="48"
                      onClick={() => (window.location.href = "/")}
                    />
                  }
                  cx="page-not-found"
                />
              )}
            />
          </Switch>
        </Router>
        <Footer />
        <DragGhost />
        <Snackbar />
      </ErrorHandler>
    </UuiContext.Provider>
  );
}

function initApp() {
  const root = createRoot(document.getElementById("root") as Element);
  root.render(
    <StrictMode>
      <UuiEnhancedApp />
    </StrictMode>
  );
}

initApp();
