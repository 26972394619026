// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-files-container {
  width: 300px;
  padding: 20px;
  border-radius: 10px;
  background-color: #f7f7f7;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: auto;
  margin-top: 3%;
}

.toggle-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 0.6rem;
}

.toggle-buttons button {
  flex: 1 1;
}

.upload-box {
  text-align: center;
}

.upload-box div {
  display: flex;
  justify-content: center;
}

.file-input {
  display: none;
}

.custom-file-upload {
  padding: 10px 20px;
  background-color: #60b0f4;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-file-upload:hover {
  background-color: #4298dc;
}
`, "",{"version":3,"sources":["webpack://./src/components/UploadManager/UploadManager.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,0CAA0C;EAC1C,kBAAkB;EAClB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".upload-files-container {\n  width: 300px;\n  padding: 20px;\n  border-radius: 10px;\n  background-color: #f7f7f7;\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n  text-align: center;\n  margin: auto;\n  margin-top: 3%;\n}\n\n.toggle-buttons {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 0.6rem;\n}\n\n.toggle-buttons button {\n  flex: 1;\n}\n\n.upload-box {\n  text-align: center;\n}\n\n.upload-box div {\n  display: flex;\n  justify-content: center;\n}\n\n.file-input {\n  display: none;\n}\n\n.custom-file-upload {\n  padding: 10px 20px;\n  background-color: #60b0f4;\n  color: white;\n  border: none;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.custom-file-upload:hover {\n  background-color: #4298dc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
