import React from "react";
import MainHeadingSection from "../../components/MainHeadingSection/MainHeadingSection";
import UploadManager from "../../components/UploadManager/UploadManager";
import "./HomePage.css";

export const HomePage: React.FC = () => {
  return (
    <main>
      <MainHeadingSection />
      <UploadManager />
    </main>
  );
};
