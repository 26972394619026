import { Button, Panel, Text } from "@epam/uui";
import React from "react";
import { NOTIFICATION_TYPE, ResumeApiData } from "../../constants";
import { downloadSingleFile } from "../../utils/downloadSingleFile";
import "./DownloadResumeSection.css";
import { downloadZipFolder } from "../../utils/downloadZipFolder";
import { useUuiContext } from "@epam/uui-core";
import { notifcationHandler } from "../../utils/notificationHandler";

interface IDownloadResumeSectionProps {
  resumeData: ResumeApiData;
}

const DownloadResumeSection: React.FC<IDownloadResumeSectionProps> = ({
  resumeData,
}) => {
  const { uuiNotifications } = useUuiContext();
  const convertedResumes = resumeData?.convertedResumes || [];
  const nonConvertedResumes = resumeData?.nonConvertedResumes || [];

  const hasConvertedResumes = convertedResumes.length > 0;
  const hasNonConvertedResumes = nonConvertedResumes.length > 0;

  const displayNoDataMarkup = () => (
    <li>
      <Text rawProps={{ style: { paddingBlock: "3px" } }}> -</Text>
    </li>
  );

  return (
    <div className="download-resume-section">
      <Panel shadow background="surface-main" cx="converted-resumes-container">
        <Text fontSize="18" fontWeight="700" color="success">
          Following are the Converted Resumes:
        </Text>
        <ul>
          {hasConvertedResumes
            ? convertedResumes?.map((resume, index) => (
                <li key={index}>
                  <Text rawProps={{ style: { paddingBlock: "3px" } }}>
                    {resume.resumeName}
                  </Text>
                </li>
              ))
            : displayNoDataMarkup()}
        </ul>
        <hr className="seperator" />
        <Text fontSize="18" fontWeight="700" color="critical">
          Following Resumes could not be converted:
        </Text>
        <ul>
          {hasNonConvertedResumes
            ? nonConvertedResumes?.map((resume, index) => (
                <li key={index}>
                  <Text rawProps={{ style: { paddingBlock: "3px" } }}>
                    {resume}
                  </Text>
                </li>
              ))
            : displayNoDataMarkup()}
        </ul>
      </Panel>

      <Panel shadow background="surface-main" cx="download-resumes-container">
        <Button
          color="primary"
          caption="Download Converted Resumes"
          isDisabled={!hasConvertedResumes}
          onClick={() => {
            convertedResumes.length === 1
              ? downloadSingleFile(convertedResumes[0].downloadLink)
              : downloadZipFolder(
                  convertedResumes.map((resume) => resume.downloadLink)
                );
            notifcationHandler(
              uuiNotifications,
              NOTIFICATION_TYPE.SUCCESS,
              "File(s) downloaded successfully"
            );
          }}
        />
        <Button
          color="primary"
          caption="Start Fresh"
          link={{ pathname: "/" }}
        />
        <Text
          rawProps={{ style: { textAlign: "center" } }}
          fontSize="14"
          fontWeight="600"
          color="secondary"
        >
          Note: Converted files are deleted after hitting <b>START FRESH</b>.
          Please download before deletion.
        </Text>
      </Panel>
    </div>
  );
};

export default DownloadResumeSection;
