// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./static/homePageBg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
}

body::before {
  content: "";
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center fixed;
  background-size: cover;
  filter: blur(9px);
  z-index: -1;
}

body > div {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.src_page-not-found__gW5L1 {
  height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/index.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,UAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,iFAAA;EACA,sBAAA;EACA,iBAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n}\n\nbody::before {\n  content: \"\";\n  position: fixed;\n  width: 100vw;\n  height: 100vh;\n  background: url(\"./static/homePageBg.jpg\") no-repeat center center fixed;\n  background-size: cover;\n  filter: blur(9px);\n  z-index: -1;\n}\n\nbody > div {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n\n.page-not-found {\n  height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page-not-found": `src_page-not-found__gW5L1`
};
export default ___CSS_LOADER_EXPORT___;
