import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Text } from "@epam/uui";
import { useUuiContext } from "@epam/uui-core";
import ModifyUploadSection from "../ModifyUploadSection/ModifyUploadSection";
import {
  DropzoneProps,
  InputAttributes,
  IFileType,
  NOTIFICATION_TYPE,
  UPLOAD_TYPE,
} from "../../constants";
import { ResumeFormatSelector } from "../ResumeFormatSelector/ResumeFormatSelector";
import { notifcationHandler } from "../../utils/notificationHandler";
import "./UploadManager.css";

const UploadManager: React.FC = () => {
  const [uploadType, setUploadType] = useState<string>(UPLOAD_TYPE.FILE);
  const [uploadedFiles, setUploadedFiles] = useState<Array<IFileType>>([]);
  const { uuiNotifications } = useUuiContext();

  const uploadFilesAndFolders = useCallback(
    (acceptedFiles: Array<IFileType>) => {
      setUploadedFiles(acceptedFiles);

      const notificationMessage =
        acceptedFiles.length > 0
          ? `${uploadType} successfully uploaded`
          : `No ${uploadType} selected`;

      notifcationHandler(
        uuiNotifications,
        acceptedFiles.length > 0
          ? NOTIFICATION_TYPE.SUCCESS
          : NOTIFICATION_TYPE.ERROR,
        notificationMessage
      );
    },
    [uploadType, uuiNotifications]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: uploadFilesAndFolders,
    noClick: true,
    noKeyboard: true,
    ...(uploadType === UPLOAD_TYPE.FOLDER && { directory: true }),
  } as DropzoneProps);

  const handleDelete = (pathName: string) => {
    const remainingFiles = uploadedFiles.filter(
      (file: IFileType) => file.path !== pathName
    );

    // if all files are deleted
    if (remainingFiles.length === 0) {
      window.location.href = "/";
      return;
    }

    setUploadedFiles(remainingFiles);
    notifcationHandler(
      uuiNotifications,
      NOTIFICATION_TYPE.SUCCESS,
      "File successfully deleted"
    );
  };

  const handleAddMore = (newFiles: Array<IFileType>) => {
    const newUniqueFiles = newFiles.filter(
      (newFile: IFileType) =>
        !uploadedFiles.find((file: IFileType) => file.path === newFile.path)
    );

    if (newUniqueFiles.length > 0) {
      // Adds new, non-duplicate files to the uploadedFiles state
      setUploadedFiles((currentFiles) => [...currentFiles, ...newUniqueFiles]);
      notifcationHandler(
        uuiNotifications,
        NOTIFICATION_TYPE.SUCCESS,
        "File(s) uploaded successfully"
      );
    } else {
      // Displays warning notification if no new files were added (all were duplicates)
      notifcationHandler(
        uuiNotifications,
        NOTIFICATION_TYPE.WARNING,
        "File(s) Already Exist"
      );
    }
  };

  return (
    <>
      <div className="upload-files-container">
        <div className="toggle-buttons">
          <Button
            rawProps={{ style: { borderRadius: "0px" } }}
            size="42"
            caption="Upload File"
            color={uploadType === UPLOAD_TYPE.FILE ? "primary" : "secondary"}
            fill={uploadType === UPLOAD_TYPE.FILE ? "solid" : "outline"}
            onClick={() => setUploadType(UPLOAD_TYPE.FILE)}
          />
          <Button
            rawProps={{ style: { borderRadius: "0px" } }}
            size="42"
            caption="Upload Folder"
            color={uploadType === UPLOAD_TYPE.FOLDER ? "primary" : "secondary"}
            fill={uploadType === UPLOAD_TYPE.FOLDER ? "solid" : "outline"}
            onClick={() => setUploadType(UPLOAD_TYPE.FOLDER)}
          />
        </div>

        <div className="upload-box">
          <Text color="primary" fontWeight="600" fontSize="18">
            Choose and upload a {uploadType}
          </Text>
          <div {...getRootProps()}>
            <input
              {...getInputProps()}
              id="fileInput"
              {...(uploadType === UPLOAD_TYPE.FOLDER
                ? ({ webkitdirectory: "" } as InputAttributes)
                : {})}
              {...(uploadType === UPLOAD_TYPE.FOLDER
                ? ({ directory: "" } as InputAttributes)
                : {})}
            />
            <Button
              size="42"
              onClick={() =>
                (
                  document.querySelector("#fileInput") as HTMLInputElement
                )?.click()
              }
              caption={`Upload ${uploadType}${
                uploadType === UPLOAD_TYPE.FILE ? "s" : ""
              }`}
            ></Button>
          </div>
          <Text rawProps={{ style: { color: "gray" } }}>
            {uploadedFiles.length
              ? `${uploadedFiles.length} file(s) chosen`
              : `No files chosen`}
          </Text>
        </div>
      </div>

      {uploadedFiles.length > 0 && (
        <>
          <ResumeFormatSelector uploadedFiles={uploadedFiles} />
          <ModifyUploadSection
            uploadedFiles={uploadedFiles}
            onDelete={handleDelete}
            onAddMore={handleAddMore}
          />
        </>
      )}
    </>
  );
};

export default UploadManager;
