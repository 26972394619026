import JSZip from "jszip";

export const downloadZipFolder = async (fileUrls: string[]) => {
  const zip = new JSZip();
  try {
    const filenameCounts: Record<string, number> = {};

    for (const fileUrl of fileUrls) {
      const response = await fetch(fileUrl);
      if (!response.ok)
        throw new Error(`Failed to fetch ${fileUrl}: ${response.statusText}`);

      const blob = await response.blob();
      const filename: string = fileUrl
        .split("/")
        .pop()
        ?.replace(".docx", "") as string;

      if (filename) {
        const count = (filenameCounts[filename] =
          (filenameCounts[filename] || 0) + 1);
        const countSuffix = count > 1 ? `(${count - 1})` : "";
        const newFileName = `${filename}${countSuffix}.docx`;

        zip.file(decodeURIComponent(newFileName).replaceAll(" ", "_"), blob);
      }
    }

    const zipBlob = await zip.generateAsync({ type: "blob" });
    const url = window.URL.createObjectURL(zipBlob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "convertedResumes.zip";

    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    console.error("There was a problem with creating the zip file:", error);
  }
};
