import React from "react";
import { useDropzone } from "react-dropzone";
import { Button, FlexRow, FlexCell, Text } from "@epam/uui";
import { DropzoneProps, IFileType } from "../../constants";
import "./ModifyUploadSection.css";

interface ModifyUploadSectionProps {
  uploadedFiles: Array<IFileType>;
  onDelete: (pathName: string) => void;
  onAddMore: (files: Array<IFileType>) => void;
}

const ModifyUploadSection: React.FC<ModifyUploadSectionProps> = ({
  uploadedFiles,
  onDelete,
  onAddMore,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onAddMore,
    noClick: true,
    noKeyboard: true,
  } as DropzoneProps);

  return (
    <div className="resume-modifier">
      <Text
        color="white"
        fontSize="24"
        fontWeight="700"
        lineHeight="30"
        cx="resume-modifier-heading"
      >
        Modify Uploaded Resumes:
      </Text>
      {uploadedFiles.map((resumeFile: IFileType, index: number) => (
        <FlexRow
          key={`${resumeFile.path}-${index}`}
          rawProps={{
            style: { borderRadius: "5px", height: "2.8rem", minWidth: "300px" },
          }}
          columnGap="6"
          margin="12"
          alignItems="center"
          background="surface-main"
          padding="6"
        >
          <FlexCell grow={1} rawProps={{ style: { paddingInline: "4px" } }}>
            <Text rawProps={{ style: { textAlign: "left" } }}>
              {resumeFile.path?.replaceAll("/", " ").replaceAll(" ", "_")}
            </Text>
          </FlexCell>
          <FlexCell width="auto">
            <Button
              size="36"
              caption="Delete"
              onClick={() => onDelete(resumeFile.path)}
            />
          </FlexCell>
        </FlexRow>
      ))}
      <FlexRow padding="12" columnGap="12" justifyContent="center">
        <div {...getRootProps()}>
          <input {...getInputProps()} id="addMoreFileInput" />
          <Button
            size="42"
            onClick={() =>
              (
                document.querySelector("#addMoreFileInput") as HTMLInputElement
              )?.click()
            }
            caption="Add more files for uploading"
          ></Button>
        </div>
        <Button
          size="42"
          caption="Cancel"
          onClick={() => window.location.reload()}
        />
      </FlexRow>
    </div>
  );
};

export default ModifyUploadSection;
