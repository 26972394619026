import React, { useEffect } from "react";
import { Blocker, LinkButton } from "@epam/uui";
import { useUuiContext, UuiError } from "@epam/uui-core";
import MainHeadingSection from "../../components/MainHeadingSection/MainHeadingSection";
import DownloadResumeSection from "../../components/DownloadResumeSection/DownloadResumeSection";
import {
  ApiError,
  DOWNLOAD_RESUME_URL,
  ExtendedCommonContexts,
  ResumeApiData,
} from "../../constants";
import "./ConvertedResumePage.css";
import { useHistory } from "react-router-dom";

const ConvertedResumePage: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [resumeData, setResumeData] = React.useState<ResumeApiData>({});
  const { contextData, uuiErrors } = useUuiContext() as ExtendedCommonContexts;
  const history = useHistory();

  const fetchdata = async () => {
    const formData = new FormData();
    contextData.uploadedResumes.forEach((file) => {
      formData.append("resume", file);
    });
    formData.append("summaryType", contextData.formatType);

    try {
      const response: any = await fetch(DOWNLOAD_RESUME_URL, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new ApiError(`Failed to fetch API`, response.status);
      }

      const result = await response.json();
      setResumeData(result);
    } catch (error: unknown) {
      handleAPIErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAPIErrors = (error: unknown) => {
    let errMessage = "An unexpected error occurred";
    let errStatusCode = 404;

    if (error instanceof ApiError) {
      errStatusCode = error.status;
      errMessage = error.message;
    } else if (error instanceof Error) {
      errMessage = error.message;
    }

    uuiErrors.reportError(
      new UuiError({
        status: errStatusCode,
        title: errMessage,
        subtitle: (
          <LinkButton
            caption="Home page"
            size="48"
            onClick={() => history.push("/")}
          />
        ),
      })
    );

    console.error("Error: ", error);
  };

  useEffect(() => {
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="converted-resume-page">
      <MainHeadingSection />
      <DownloadResumeSection resumeData={resumeData} />
      <Blocker isEnabled={isLoading} />
    </div>
  );
};

export default ConvertedResumePage;
