import React from "react";
import { RichTextView } from "@epam/uui";
import "./MainHeadingSection.css";

const MainHeadingSection: React.FC = () => {
  return (
    <RichTextView rawProps={{ style: { textAlign: "center" } }}>
      <h1 className="main-heading">
        <strong>Create Your EPAM Professional Resume</strong>
      </h1>
      <h4 className="sub-heading">
        Generate a professional EPAM format resume in minutes with our
        easy-to-use resume generator
      </h4>
    </RichTextView>
  );
};

export default MainHeadingSection;
