// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resume-modifier {
  text-align: center;
  margin-bottom: 1.5rem;
}

.resume-modifier-heading {
  font-family: "Source Sans Pro", sans-serif;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 1.5);
  color: #fff;
  margin-block: 2rem 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/ModifyUploadSection/ModifyUploadSection.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,0CAA0C;EAC1C,2CAA2C;EAC3C,WAAW;EACX,uBAAuB;AACzB","sourcesContent":[".resume-modifier {\n  text-align: center;\n  margin-bottom: 1.5rem;\n}\n\n.resume-modifier-heading {\n  font-family: \"Source Sans Pro\", sans-serif;\n  text-shadow: 3px 3px 6px rgba(0, 0, 0, 1.5);\n  color: #fff;\n  margin-block: 2rem 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
