import {
  ErrorNotification,
  SuccessNotification,
  WarningNotification,
  Text,
} from "@epam/uui";
import { INotification } from "@epam/uui-core";
import { NOTIFICATION_TYPE } from "../constants";

export const notifcationHandler = (
  uuiNotifications: any,
  type: keyof typeof NOTIFICATION_TYPE,
  message: string
) => {
  const components = {
    [NOTIFICATION_TYPE.SUCCESS]: SuccessNotification,
    [NOTIFICATION_TYPE.WARNING]: WarningNotification,
    [NOTIFICATION_TYPE.ERROR]: ErrorNotification,
  };

  const NotificationComponent = components[type];

  uuiNotifications
    .show(
      (props: INotification) => (
        <NotificationComponent {...props}>
          <Text size="36" fontSize="14">
            {message}
          </Text>
        </NotificationComponent>
      ),
      { position: "bot-right", duration: 3 }
    )
    .catch(() => null);
};
